import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import { getData, postData, postData1 } from "../../services/http.service";
import Constants from "../../services/constant";
import service_dummy from "../../assets/demo/flags/service-dummy.svg";
import { Chips } from "primereact/chips";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
const CreateServices = () => {
    const toast = useRef(null);
    const history = useHistory();

    const [serviceImg, setServiceImg] = useState(null);
    const [serviceInfo, setServiceInfo] = useState({
        serviceName: "",
        seasonId: "",
        // season:"",
        imageColorCode: "",
        minPercentage: "",
        commissionPercentage: "",
        note: "",
    });

    const [allSeason, setAllSeason] = useState([]);

    const [err, setErr] = useState({});

    const handleChangeService = (e) => {
        const { name, value } = e.target;

        setServiceInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });

        setErr({ ...err, [name]: "" });
    };
    const validateForm = () => {
        let errors = {};
        if (!serviceInfo.serviceName) {
            errors.serviceName = "Service Name is required";
        }
        if (!serviceInfo.seasonId) {
            errors.seasonId = "Season Name is required";
        }
        if (!serviceInfo.imageColorCode) {
            errors.imageColorCode = "image Background color is required";
        }
        if (!serviceInfo.minPercentage) {
            errors.minPercentage = "Minimum Percentage is required";
        }
        if (!serviceImg) {
            errors.serviceImage = "service Image is required";
        }

        if (!serviceInfo.commissionPercentage) {
            errors.commissionPercentage = "Commission Percentage is required";
        }
        if (!serviceInfo.note) {
            errors.note = "Note is required";
        }
        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            const formData = new FormData();
            formData.append("serviceImage", serviceImg);
            postData1(Constants.END_POINT.UPLOAD_IMAGE, formData)
                .then((res) => {
                    if (res.success) {
                        let seasonsId = serviceInfo.seasonId.map((item) => {
                            return item._id;
                        });
                        let payload = {
                            serviceImage: res.data.image,
                            serviceName: serviceInfo.serviceName,
                            seasonId: seasonsId,
                            imageColorCode: serviceInfo.imageColorCode,
                            minPercentage: serviceInfo.minPercentage,
                            commissionPercentage: serviceInfo?.commissionPercentage,
                            note: serviceInfo?.note,
                        };
                        postData(Constants.END_POINT.CREATE_SERVICE, payload)
                            .then((res) => {
                                if (res.success) {
                                    toast.current.show({ severity: "info", summary: "", detail: "Service has been Created Succesfully", life: 3000 });
                                    setTimeout(() => history.push("/services"), 1000);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            window.scrollTo({
                top: 250,
                left: 0,
                behavior: "smooth",
            });
        }
    };
    const [allPercentage, setAllPercentage] = useState(["10", "20", "30", "40", "50", "60", "80", "100"]);

    const getAllSeasons = () => {
        getData(Constants.END_POINT.ALL_SEASONS)
            .then((res) => {
                if (res.success) {
                    let array = res.data.map((item) => {
                        return {
                            _id: item._id,
                            seasonName: item.seasonName,
                        };
                    });
                    setAllSeason(array);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getAllSeasons();
    }, []);

    const search = (event) => {
        setAllPercentage(["10", "20", "30", "40", "50", "60", "80", "100"]);
    };

    return (
        <div className="grid">
            <div className="col-12 md:col-12 mx-auto">
                <Toast ref={toast} />
                <div className="card ">
                    <div className=" flex justify-content-center">
                        <h3>Add Service</h3>
                        <div className="ml-4 align-right"></div>
                    </div>
                    <div className="p-fluid grid">
                        <div className="field col-12 md:col-12">
                            <div className="weapon-image-wrapper mt-4">
                                <div className="weapon-image-div">
                                    <img src={serviceImg ? URL.createObjectURL(serviceImg) : service_dummy} alt="" className="weaponImageTag" />
                                    {serviceImg !== null ? (
                                        <div className="weapon-edit-div">
                                            <i onClick={() => setServiceImg(null)} className="fa-sharp fa-solid fa-trash delete"></i>
                                        </div>
                                    ) : null}
                                </div>

                                <input
                                    type="file"
                                    id="serviceImage"
                                    name="serviceImage"
                                    onChange={(e) => {
                                        setServiceImg(e.target.files[0]);
                                        setErr({ ...err, serviceImage: "" });
                                    }}
                                    onClick={(event) => {
                                        event.currentTarget.value = null;
                                    }}
                                    hidden
                                />
                                <label htmlFor="serviceImage" className="weaponImageLabel">
                                    Add Service Image
                                </label>
                                <div className="text-danger">{err.serviceImage}</div>
                            </div>
                            {/* <div className="text-danger">{err.serviceName}</div> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="serviceName" name="serviceName" onChange={handleChangeService} value={serviceInfo.serviceName} />
                                <label htmlFor="serviceName">Service Name</label>
                            </span>
                            <div className="text-danger">{err.serviceName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <MultiSelect value={serviceInfo.seasonId} name="seasonId" onChange={handleChangeService} options={allSeason} optionLabel="seasonName" display="chip" placeholder="" maxSelectedLabels={6} className="w-full" />
                                <label htmlFor="serviceName">Season</label>
                            </span>
                            <div className="text-danger">{err.serviceName}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputText id="imageColorCode" name="imageColorCode" onChange={handleChangeService} value={serviceInfo.imageColorCode} />
                                <label htmlFor="imageColorCode">Image Color Code</label>
                            </span>
                            <div className="text-danger">{err.imageColorCode}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                {/* <InputText id="minPercentage" name="minPercentage" onChange={(e)=>{setServiceInfo(e.target.value);setErr({ ...err, minPercentage: "" });}} value={serviceInfo.minPercentage} /> */}
                                <AutoComplete
                                    value={serviceInfo.minPercentage}
                                    suggestions={allPercentage}
                                    completeMethod={search}
                                    onChange={(e) => {
                                        setServiceInfo((prev) => ({ ...prev, minPercentage: e.value }));
                                        setErr({ ...err, minPercentage: "" });
                                    }}
                                    // dropdown
                                />
                                <label htmlFor="minPercentage">Minimum Percentage</label>
                            </span>
                            <div className="text-danger">{err.minPercentage}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <AutoComplete
                                    value={serviceInfo.commissionPercentage}
                                    suggestions={allPercentage}
                                    completeMethod={search}
                                    onChange={(e) => {
                                        setServiceInfo((prev) => ({ ...prev, commissionPercentage: e.value }));
                                        setErr({ ...err, commissionPercentage: "" });
                                    }}
                                />
                                <label htmlFor="commissionPercentage">Commission Percentage</label>
                            </span>
                            <div className="text-danger">{err.commissionPercentage}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                            <span className="p-float-label mt-4">
                                <InputTextarea
                                    value={serviceInfo.note}
                                    suggestions={allPercentage}
                                    completeMethod={search}
                                    onChange={(e) => {
                                        setServiceInfo((prev) => ({ ...prev, note: e.target.value }));
                                        setErr({ ...err, note: "" });
                                    }}
                                    rows={3}
                                />
                                <label htmlFor="note">Note</label>
                            </span>
                            <div className="text-danger">{err.note}</div>
                        </div>
                    </div>

                    <div className="flex justify-content-center mt-3 ">
                        {
                            <Button type="button" className="p-button-md p-button-success" onClick={handleSubmit}>
                                Add Service
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateServices;
